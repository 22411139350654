<template>
  <div class="media-container" :key="change">
    <swiper ref="mySwiper" :options="swiperOption" @slideChange="sliderChange">
      <swiper-slide class="media-item" v-for="(m, i) in media" :key="i">
        <span v-if="showRemoveIcon" class="remove-icon" @click="removeItem(m)">
          <v-icon dark x-small>mdi-close</v-icon> Remove
        </span>
        <component
          :is="currentComponent(m)"
          :src="mediaSource(m)"
          :max-height="maxHeight"
          :rounded="rounded"
          :cover="cover"
        />
      </swiper-slide>
    </swiper>

    <div class="text-center icons" v-if="!hideDots && media.length > 1">
      <v-icon
        v-for="i in media.length"
        :key="i"
        :color="i - 1 === current ? 'primary' : 'grey-light'"
        :size="i - 1 === current ? '12px' : '10px'"
        >mdi-record</v-icon
      >
    </div>
    <div v-else class="py-3"></div>
  </div>
</template>

<script>
import SingleImage from "./single-image.vue";
import VideoPlayer from "./video-player.vue";

import { VIDEO_EXTENSIONS_ARRAY } from "@/constants";

import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: { swiper, swiperSlide },
  props: {
    media: {
      type: Array,
      default: () => [],
    },
    showRemoveIcon: {
      type: Boolean,
      default: false,
    },
    hideDots: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "350px",
    },
    cover: {
      type: Boolean,
      default: false,
    },
    rounded: { type: Boolean, default: true },
  },
  data() {
    return {
      current: 0,
      swiperOption: {
        loop: false,
        spaceBetween: 30,
      },
      change: false,
    };
  },
  computed: {},
  methods: {
    currentComponent(src) {
      if (typeof src === "string") {
        let component = VideoPlayer;
        let strParts = src.split(".");
        let extension = strParts[strParts.length - 1];

        if (!extension) return component;

        if (!VIDEO_EXTENSIONS_ARRAY.includes(extension.toLowerCase())) {
          component = SingleImage;
        }

        return component;
      } else {
        return src.type.includes("video") ? VideoPlayer : SingleImage;
      }
    },
    mediaSource(media) {
      if (typeof media === "string") {
        return media;
      }
      return media.src;
    },
    sliderChange() {
      this.current = this.$refs.mySwiper.swiper.activeIndex;
    },
    removeItem(m) {
      this.$emit("remove-media", m);
      if (m.type.includes("video")) this.change = !this.change;
    },
  },
};
</script>

<style lang="scss" scoped>
.media-container {
  .media-item {
    height: auto;
    position: relative;
    min-height: 300px;
    display: grid;
    place-items: center;

    .remove-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      display: inline-block;
      padding: 5px 0.4rem;
      border-radius: 20px;
      z-index: 11111;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: #ffffff;
      background: #00000085;

      i {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        padding: 2px;
      }
    }
  }
  .icons {
    position: relative;
    top: 0.7rem;
  }
}
</style>
