<template>
  <AppPage :dark-app-bar="true" app-bar-color="transparent">
    <template v-slot:app-bar>
      <v-btn @click="$router.back()" icon rounded class="grey-light grey--text">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        v-if="menuItems.length > 0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list v-for="(item, index) in menuItems" :key="index">
          <v-list-item @click="item.action" :disbaled="item.disabled">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <loader :loading="loading" overlay>
      <div v-if="activePetDetail">
        <div class="img-container">
          <Media
            :media="activePetDetail.media"
            cover
            :rounded="false"
            max-height="400px"
          />
        </div>

        <v-container class="img-container inner-container">
          <div class="d-flex align-items-center
           justify-content-between">
            <div>
              <h2 class="d-flex align-items-center">
                {{ activePetDetail.name }}

                <v-icon
                  v-if="activePetDetail.featured"
                  small
                  color="primary"
                  class="ml-2"
                  >mdi-star</v-icon
                >
              </h2>

              <p class="pet-kind">
                <span>{{ activePetDetail.race && activePetDetail.race.name }}</span
                >, <span>{{ activePetDetail.age }}</span>
              </p>
            </div>

            <button class="gift-button" @click="goToDonationPage()">
              <img src="@/assets/images/giftbox.png" width="25" alt="giftbox">
            </button>
          </div>

          <v-card color="bg-yellow" class="rounded-lg pa-5 my-3" elevation="0">
            <div
              v-for="(l, index) in looking_for"
              :key="l.text"
              class="d-flex align-items-center"
              :class="index !== looking_for.length - 1 ? 'mb-4' : ''"
            >
              <!-- <v-icon class="mr-3" color="primary">mdi-home-outline</v-icon> -->
              <img :src="l.icon" alt="" class="mr-3" />
              <span class="small">{{ l.text }}</span>
            </div>
          </v-card>

          <h3 class="mt-6">{{ $vuetify.lang.t("$vuetify.info") }}</h3>
          <div class="description">
            {{
              activePetDetail.description ||
              `{!!${$vuetify.lang.t("$vuetify.no_description")}!!}`
            }}
          </div>

          <h3 class="mt-10">{{ $vuetify.lang.t("$vuetify.owner") }}</h3>
          <div class="d-flex align-items-center">
            <v-avatar size="60px">
              <img
                :src="
                  activePetDetail.seller.avatar
                    ? activePetDetail.seller.avatar
                    : noImgAvatar
                "
                alt="John"
              />
            </v-avatar>

            <div class="owner-details">
              <p class="bold">{{ activePetDetail.seller.name }}</p>
              <p class="light">{{ activePetDetail.seller.country }}</p>
            </div>
          </div>

          <ul class="lists">
            <li class="lists-item" v-if="meta_data.garden">
              <img src="@/components/svg/icon/garden_square.svg" />
              <p class="text">Requires Garden Space</p>
            </li>
            <li class="lists-item" v-if="meta_data.garden_size">
              <img src="@/components/svg/icon/garden_square.svg" />
              <p class="text" v-html="gardenSize"></p>
            </li>
            <li class="lists-item" v-if="meta_data.garden">
              <img src="@/components/svg/icon/garden_space.svg" />
              <p class="text">
                Garden size {{ meta_data.garden_length }} x
                {{ meta_data.garden_width }}
              </p>
            </li>
            <li class="lists-item" v-if="meta_data.active_hours">
              <v-icon color="primary">mdi-clock</v-icon>
              <p class="text">
                {{ $vuetify.lang.t("$vuetify.active_hour") }}
                {{ meta_data.active_hours }}
              </p>
            </li>

            <li
              class="lists-item"
              v-for="interaction in petInteractions"
              :key="interaction.title"
            >
              <v-icon
                :color="
                  interaction.title.toLowerCase().includes('not') ||
                  interaction.title.toLowerCase().includes('niet') ||
                  interaction.title.toLowerCase().includes('pas')
                    ? 'error'
                    : 'primary'
                "
                >{{ interaction.icon }}</v-icon
              >
              <p
                class="text"
                :class="
                  interaction.title.toLowerCase().includes('not') ||
                  interaction.title.toLowerCase().includes('niet') ||
                  interaction.title.toLowerCase().includes('pas')
                    ? 'error--text'
                    : ''
                "
              >
                {{ interaction.title }}
              </p>
            </li>
          </ul>

          <v-divider class="my-4"></v-divider>

          <div class="text-center">
            <!-- <v-btn text color="red" class="mx-auto">{{
              $vuetify.lang.t("$vuetify.report_listing")
            }}</v-btn> -->

            <app-button
              class="mt-3"
              :disabled="!!activePetDetail.user_adoption"
              @click="
                $router.push(`/pet-detail/${$route.params.id}/interested-in`)
              "
            >
              {{
                activePetDetail.user_adoption
                  ? activePetDetail.user_adoption.status
                  : $vuetify.lang.t("$vuetify.i_am_interested")
              }}
            </app-button>

            <app-button
              @click="cancelRequest"
              :loading="cancelLoading"
              color="error"
              class="mt-3"
              v-if="
                activePetDetail.user_adoption &&
                activePetDetail.user_adoption.status.toLowerCase() === 'review'
              "
            >
              {{ $vuetify.lang.t("$vuetify.cancel_request") }}
            </app-button>
          </div>
        </v-container>
      </div>
    </loader>
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("pet");

import Media from "@/components/media/media";
import noImgAvatar from "@/assets/images/no-img.png";
export default {
  name: "pet-detail",
  components: { Media },
  data() {
    return {
      appBarColor: "transparent",
      darkAppBar: true,
      cancelLoading: false,
      noImgAvatar,
    };
  },
  computed: {
    ...mapState(["activePetDetail", "loading"]),
    menuItems() {
      return [
        // {
        //   title: this.$vuetify.lang.t("$vuetify.report_post"),
        //   action: () => window.showAlert("Comming soon.!", "Yet to come"),
        // },
      ];
    },
    looking_for() {
      if (!this.activePetDetail) return [];
      const { co_adoption, adoption } = this.activePetDetail;

      return [
        {
          icon: require("@/components/svg/icon/home.svg"),
          text: this.$vuetify.lang.t("$vuetify.looking_for_a_new_home"),
          show: adoption,
        },
        {
          icon: require("@/components/svg/icon/bone-icon.svg"),
          text: this.$vuetify.lang.t("$vuetify.go_for_a_walk_with_someone"),
          show: co_adoption,
        },
      ].filter((a) => a.show);
    },
    meta_data() {
      return this.activePetDetail.meta_data
        ? this.activePetDetail.meta_data
        : {};
    },
    gardenSize() {
      const { garden_size } = this.meta_data;
      if (garden_size) {
        if (garden_size === "no")
          return this.$vuetify.lang.t("$vuetify.no_garden");
        if (garden_size === "small")
          return this.$vuetify.lang.t("$vuetify.less_than_200_square_meter");
        if (garden_size === "big")
          return this.$vuetify.lang.t("$vuetify.more_than_200_square_meter");
        return "No Size Available";
      }
      return null;
    },
    petInteractions() {
      if (!this.activePetDetail) return [];
      if (!this.activePetDetail.meta_data) return [];
      const { pet_interaction } = this.activePetDetail.meta_data;
      if (!pet_interaction) return [];
      // kids_friendly
      // let not = this.$vuetify.lang.t("$vuetify.not") + " ";
      return [
        {
          // title: `${
          //   !pet_interaction.includes("kids_friendly") ? not : ""
          // } ${this.$vuetify.lang.t("$vuetify.kids_firendy")}`,
          title: !pet_interaction.includes("kids_friendly")
            ? this.$vuetify.lang.t("$vuetify.not_kids_firendy")
            : this.$vuetify.lang.t("$vuetify.kids_firendy"),
          icon: "mdi-account-child-circle",
        },
        {
          title: !pet_interaction.includes("dog_friendly")
            ? this.$vuetify.lang.t("$vuetify.not_dogs_firendy")
            : this.$vuetify.lang.t("$vuetify.dogs_firendy"),
          icon: "mdi-dog",
        },
        {
         title: !pet_interaction.includes("other")
            ? this.$vuetify.lang.t("$vuetify.not_other_animals_friendly")
            : this.$vuetify.lang.t("$vuetify.other_animals_friendly"),
          icon: "mdi-lock",
        },
      ];
    },
  },
  methods: {
    ...mapActions(["FETCH_ACTIVE_PET_DETAILS"]),
    onScroll() {
      if (window.scrollY > 50) {
        (this.appBarColor = "#fff"), (this.darkAppBar = false);
      } else {
        (this.appBarColor = "transparent"), (this.darkAppBar = true);
      }
    },
    getInteraction(interaction) {
      let title = "Kids Friendly";
      let icon = "mdi-account-child-circle";

      if (interaction === "dog_friendly") {
        title = "Dog Friendly";
        icon = "mdi-dog";
      }

      if (interaction === "other") {
        title = "Other Animal Friendly";
        icon = "mdi-lock ";
      }

      return {
        title,
        icon,
      };
    },
    async cancelRequest() {
      try {
        this.cancelLoading = true;
        const { user_adoption } = this.activePetDetail;
        await this.axios.delete(`/user/remove-adoption/${user_adoption.id}`);
      } catch (error) {
        console.log(error);
      } finally {
        this.cancelLoading = false;
        this.FETCH_ACTIVE_PET_DETAILS(this.$route.params.id);
      }
    },
    goToDonationPage () {
      this.$store.commit("SET_ACTIVE_PET_DETAIL", this.activePetDetail);
      this.$router.push(`/make-donation?id=${this.activePetDetail.id}&type=P`);
    }
  },
  created() {
    this.FETCH_ACTIVE_PET_DETAILS(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  position: relative;
  top: -56px;

  &.inner-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
p {
  margin-bottom: 0;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  color: #010101;
  margin: 8px 0;
}

.pet-kind {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #010101;
}

span.small {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  opacity: 0.7;
}
.description {
  white-space: pre-wrap;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  opacity: 0.5;
  word-break: break-all;
}

.owner-details {
  margin-left: 1rem;
  p.bold {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }

  p.light {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #000000;

    opacity: 0.5;
  }
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
}

.lists {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 1.5rem;
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p.text {
      margin-left: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #000000;
      opacity: 0.7;
      word-break: break-all;
    }
  }
}

.gift-button{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #03364ded;
  display: grid;
  place-items: center;
}
</style>
